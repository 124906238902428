<template>
  <div class="box">
    <div class="headline">部门设置</div>
    <div class="content">
      <p>
        操作步骤基本与公司设置一致，【系统设置】→【公司与部门设置】
        注意的是，如果在创建团队的时候选择了导入数据，可以直接看到导入部门“财务部”信息
      </p>
      <img src="../../assets/specificationImgs/img13.png" alt="" width="100%">
    </div>
  </div>
</template>

<script>
export default {};
</script>
